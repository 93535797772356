import React from 'react';
import Cell from './Cell';

class BoardStats extends React.Component{
    getBoardStats = ()=>{
        let colors = {}
        this.props.data.forEach(row => {
            row.forEach((cell)=>{
                if(!cell.deleted){
                    if(!Object.keys(colors).includes(""+cell.color+"")){
                        colors[cell.color]=1;
                    }else{
                        colors[cell.color]=colors[cell.color]+1;
                    }
                }
            });
        });
        return colors;
    }
    render(){
        const colors= this.getBoardStats()
        return Object.keys(colors).map((color)=>{
            return <Cell key={color} value={{color:parseInt(color)}} text={colors[color]}/>
        });
    }
}

export default BoardStats;