import React from 'react';
import './App.css';
import Game from './Game';
import Sphere from './Sphere'
import { render } from '@testing-library/react';

class App extends React.Component {
  componentDidMount(){
    // console.log("Initializing")
    //     const hubConnection = new HubConnectionBuilder()
    //     .withUrl("http://localhost:5000/hub")
    //     .withAutomaticReconnect()
    //     .build();
    //     this.setState({hubConnection},()=>{
    //         this.state.hubConnection
    //         .start()
    //         .then(() => console.log('Connection started!'))
    //         .catch(err => console.log('Error while establishing connection :('));
            
    //     });
  }
  render(){
    return (
      <div className="App">
        {/* <Sphere/> */}
        <Game rows="10" cols="10"/>
      </div>
    );
  }
}

export default App;
