import React from 'react';

class Sphere extends React.Component{
    render(){
        return(
            <svg height="44" width="44">
                <defs>
                    <radialGradient id="myGradient">
                        <stop offset="10%" stopColor="gold" />
                        <stop offset="95%" stopColor="red" />
                    </radialGradient>
                </defs>       
                <circle cx="20" cy="20" r="20" fill="url(#myGradient)" />
            </svg>
        );
    }
}

export default Sphere;