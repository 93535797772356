import React from 'react';
import Board from './Board';
import Scoreboard from './Scoreboard'
import Toolbar from './Toolbar'
import './Game.css'
import {HubConnectionBuilder} from "@microsoft/signalr";
import BoardStats from './BoardStats';
class Game extends React.Component{

    componentDidMount = () =>{
       
    }

    constructor(props){
        super(props);
        this.state={
            data : [],
            undoStack: [],
            redoStack: [],
            rows: this.props.rows,
            cols: this.props.cols,
            score: 0,
            currentSelection: 0,
            hubConnection: null,
            gameOver: false,
            shouldPlaySound: false
        };
        var id = 0;
        for(var i=0;i<this.state.rows;i++){
            let cols = [];
            for(var j=0;j<this.state.cols;j++){
                var obj ={
                    id: id,
                    row: i,
                    col: j,
                    deleted: false,
                    selected:false,
                    color:Math.ceil(Math.random() * 5)
                };
                id = id +1 ;
                cols.push(obj);
            }
            this.state.data.push(cols);
        }
    }

    isValid = (row,col)=>{
        return row>=0 && row < this.state.rows && col >=0 && col<this.state.cols
    }

    getSameColorNeighbors = (arr,value)=>{
        const {row, col } = value;
        const {data} = this.state;
        //south
        if(this.isValid(row+1,col)&&!data[row+1][col].deleted&&data[row+1][col].color===value.color&&arr.indexOf(data[row+1][col])===-1){
            arr.push(data[row+1][col]);
            this.getSameColorNeighbors(arr,data[row+1][col]);
        }
        //east
        if(this.isValid(row,col+1)&&!data[row][col+1].deleted&&data[row][col+1].color===value.color&&arr.indexOf(data[row][col+1])===-1){
            arr.push(data[row][col+1]);
            this.getSameColorNeighbors(arr,data[row][col+1]);
        }

        //north
        if(this.isValid(row-1,col)&&!data[row-1][col].deleted&&data[row-1][col].color===value.color&&arr.indexOf(data[row-1][col])===-1){
            arr.push(data[row-1][col]);
            this.getSameColorNeighbors(arr,data[row-1][col]);
        }

        //west
        if(this.isValid(row,col-1)&&!data[row][col-1].deleted&&data[row][col-1].color===value.color&&arr.indexOf(data[row][col-1])===-1){
            arr.push(data[row][col-1]);
            this.getSameColorNeighbors(arr,data[row][col-1]);
        }
        
    }

    newGame = () =>{
        let data = this.state.data;
        for(var i=0;i<this.state.rows;i++){
            for(var j=0;j<this.state.cols;j++){
                let cell = this.state.data[i][j];
                cell.deleted = false;
                cell.selected = false;
                cell.color = Math.ceil(Math.random() * 5)
            }
        }
        this.setState({
            data:data,
            redoStack:[],
            undoStack:[],
            score: 0,
            currentSelection: 0,
            gameOver: false
        })
    }

    liveCellCount = (data) =>{
        var cellCount=0;
        data.forEach((row)=>{
            row.forEach((item)=>{
                if(!item.deleted){
                    cellCount++;
                }
            })
        })
        return cellCount;
    }

    undo = () =>{
        if(this.state.undoStack.length>0){
            const nowLiveCount = this.liveCellCount(this.state.data);
            let redoStack = this.state.redoStack;
            redoStack.push(JSON.parse(JSON.stringify(this.state.data)));
            let undoStack  = this.state.undoStack;
            let data = undoStack.pop();
            const afterUndoLiveCount = this.liveCellCount(data); 
            const diff = afterUndoLiveCount - nowLiveCount;
            this.setState({data:data,undoStack:undoStack,redoStack:redoStack});
            this.setState({score:this.state.score-(diff*(diff-1)*10)});
            this.setState({gameOver:this.detectGameOver(data)});
        }
    }

    redo = () =>{
        if(this.state.redoStack.length>0){
            const nowLiveCount = this.liveCellCount(this.state.data);
            let undoStack = this.state.undoStack;
            undoStack.push(JSON.parse(JSON.stringify(this.state.data)));
            this.setState(undoStack);
            let redoStack  = this.state.redoStack;
            let data = redoStack.pop();
            const afterUndoLiveCount = this.liveCellCount(data); 
            const diff = nowLiveCount - afterUndoLiveCount;
            this.setState({data:data,redoStack:redoStack});
            this.setState({score:this.state.score+(diff*(diff-1)*10)});
            this.setState({gameOver:this.detectGameOver(data)});
        }
    }

    gravity = (data) =>{
        const {rows,cols} = this.state;
        for(var j = cols-1;j>=0;j--){
            for(var i=rows-1;i>=1;i--){
                if(data[i][j].deleted){
                    //find first non empty cell and exchange
                    let r = i-1;
                    while(data[r][j].deleted && r>0){
                        r--;
                    }
                    if(!data[r][j].deleted){
                        //exchange
                        this.swap(data,i,j,r,j);
                    }
                }
            }
        }
        
    }

    compact =(data) =>{
        const {rows,cols} = this.state;
        for(var j = cols-1;j>=1;j--){
            if(this.colEmpty(data,j)){
                //find lowest column that is non empty
                let col = j-1;
                while(this.colEmpty(data,col)&&col>0){
                    col--
                }
                for(var i=0;i<rows;i++){
                    this.swap(data,i,j,i,col);
                }
            }
        }
    }

    colEmpty = (data,col)=>{
        for(let i=0;i<data.length;i++){
            if(!data[i][col].deleted){
                return false;
            }
        }
        return true
    }

    detectGameOver = (data) =>{
        const {rows,cols} = this.state;
        for(let i=0;i<rows;i++){
            for(let j=0;j<cols;j++){
                if(data[i][j].deleted){
                    continue;
                }
                const color = data[i][j].color;
                if((this.isValid(i-1,j)&&!data[i-1][j].deleted&&data[i-1][j].color===color)
                ||(this.isValid(i,j+1)&&!data[i][j+1].deleted&&data[i][j+1].color===color)
                ||(this.isValid(i+1,j)&&!data[i+1][j].deleted&&data[i+1][j].color===color)
                ||(this.isValid(i,j-1)&&!data[i][j-1].deleted&&data[i][j-1].color===color)){
                    return false;
                }
            }
        }
        return true;
    }

    swap=(data,i,j,k,l)=>{
        const trow = data[i][j].row;
        const tcol = data[i][j].col;
        
        data[i][j].row = data[k][l].row;
        data[i][j].col = data[k][l].col;
        data[k][l].row = trow;
        data[k][l].col = tcol;
        [data[i][j], data[k][l]] = [data[k][l], data[i][j]]
    }

    handleCellSelected = (value)=>{
        let data = this.state.data;

        let arr = [];
        this.getSameColorNeighbors(arr, value);
        
        if(arr.length>1){
            if(!value.selected){
                data.forEach((row)=>{
                    row.forEach((item)=>{
                        item.selected=false;
                    });
                });
                arr.forEach((obj)=>{
                    obj.selected = true;
                });
                this.setState({currentSelection:arr.length*(arr.length-1)*10})
            }
            else{
                let undoStack = this.state.undoStack;
                undoStack.push(JSON.parse(JSON.stringify(this.state.data)));
                this.setState({undoStack:undoStack,redoStack:[]});
                this.setState({currentSelection:0})
                this.setState({score:this.state.score + arr.length*(arr.length-1)*10})

                arr.forEach((obj)=>{
                    obj.selected = false;
                    obj.deleted = true;
                });
                this.gravity(data);
                this.compact(data);
                this.setState({gameOver:this.detectGameOver(data)});
                if(this.state.shouldPlaySound){
                    var audio = new Audio('submarine.wav');
                    audio.play();
                }
            }
        }
        
        this.setState(data)
    }
    
    testSignalR = () =>{
        this.state.hubConnection.invoke(
            "SendMessage","gavi",JSON.stringify(this.state.data)
        );
        console.log("sent");
    }

    handleSoundChanged = (event)=>{
        const target = event.target;
        const value = target.name === 'shouldPlaySound' ? target.checked : target.value;
        const name = target.name;
        this.setState({
        [name]: value
        });
    }

    render(){
        return (
            <div className="game">
                 <BoardStats data={this.state.data}/>
                <Scoreboard currentSelection={this.state.currentSelection} score={this.state.score} gameOver={this.state.gameOver}/>
                <Board data={this.state.data} onCellSelected={this.handleCellSelected}/>  
                <Toolbar
                    onUndo={this.undo}
                    onRedo={this.redo}
                    onNewGame={this.newGame}
                    onTestSignalR={this.testSignalR}
                    onGameOverDetect={this.detectGameOver}
                    onSoundChanged={this.handleSoundChanged}
                    shouldPlaySound={this.state.shouldPlaySound}
                />
            </div>
        );
    }
}

export default Game;