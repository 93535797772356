import React from 'react'

class Scoreboard extends React.Component{
    render(){
        return(
            <div>
                <label>Current Selection: <b>{this.props.currentSelection}</b></label>
                <label>Score: <b>{this.props.score}</b></label>
                <label>GameOver: <b>{this.props.gameOver?'Yes':'No'}</b></label>
                
            </div>
        )
    }
}

export default Scoreboard