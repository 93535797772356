import React from 'react';
import './Cell.css';

class Cell extends React.Component{
    render(){
        const {value,onSelected}=this.props;
        const colors = ["grey","red","blue","purple","green","darkorange"] 
        if(!value.deleted){
            let clsSelected = "";
            if(value.selected){
                clsSelected = "selected";
            }
            return (
                <svg height="44" width="44" className={clsSelected}>
                    <g>
                    <circle cx="22" cy="22" r="20" stroke={value.selected?"black":"white"} strokeWidth="2" fill={colors[value.color]} onClick={(e)=>onSelected(this.props.value)} />
                    <text x="50%" y="50%" textAnchor="middle" stroke="#ffffff" strokeWidth="1px" fill="#000000" dy=".3em">{this.props.text}</text>
                    </g>
                </svg>
            );
        }
        else{
            return <svg height="44" width="44">
                    </svg>;
        }
    }
}

export default Cell;