import React from 'react';
import Cell from './Cell';
import './Board.css'

class Board extends React.Component{

    renderRow(row){
        return row.map((item)=>{
            return <Cell value={item} key={item.id} onSelected={this.props.onCellSelected}></Cell>
        });
    }
    
    render(){
        const renderedRows = this.props.data.map((row,index)=>{
            return [this.renderRow(row),<br key={this.props.data.length * this.props.data[0].length + index}/>];
        });
        return (
            <div className="board" style={{width:this.props.data[0].length*44}}>{renderedRows}</div>
        );
    }
}

export default Board;