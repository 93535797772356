import React from 'react'

class Toolbar extends React.Component{
    render(){
        return (
            <div key="toolBar">
                <button onClick={this.props.onNewGame}>New Game</button>
                <button onClick={this.props.onUndo}>undo</button>
                <button onClick={this.props.onRedo}>redo</button>
                {/* <button onClick={this.props.onTestSignalR}>Test SignalR</button> */}
                <label>
                <input name="shouldPlaySound" type="checkbox" checked={this.props.shouldPlaySound} onChange={this.props.onSoundChanged}/>
                Sound</label>
            </div>
        )
    }
}

export default Toolbar